import React from "react";

import {  auth, db } from "../firebase";

import {  Modal, ModalBody, Button, Form } from 'react-bootstrap';
import { Link  } from "react-router-dom";

import Swal from 'sweetalert2'
import toast from 'react-hot-toast';

import 'react-day-picker/dist/style.css';

  

class Profile extends React.Component {
  constructor(props) {
    
    super(props) 
    this.state = {    
      subscribed:false, 
      idsToShow: [],
      tables: [
      { idtable: "3-7x0b1tgh7agmf6hv", name: "Immersive Roulette", img: "evolution-3-lr6t4k3lcd4qgyrk-2.jpg" },
      { idtable: "3-48z5pjps3ntvqc1b", name: "Auto-Roulette", img: "evolution-3-48z5pjps3ntvqc1b-2.jpg" },
      { idtable: "3-vctlz20yfnmp1ylr", name: "Roulette", img: "Roulette.jpg" },
      { idtable: "3-wzg6kdkad1oe7m5k", name: "VIP Roulette", img: "VIP-Roulette.jpg" },
      { idtable: "3-lkcbrbdckjxajdol", name: "Speed Roulette", img: "Speed-Roulette.jpg" },
      { idtable: "3-LightningSpain01", name: "Ruleta Relámpago en Vivo", img: "Roleta-ao-Vivo.jpg" },
      { idtable: "3-TRLRou0000000001", name: "Türkçe Lightning Rulet", img: "turke.jpg" },
      { idtable: "3-ovfzgqahvicqtiwu", name: "Lightning Roulette Italia", img: "10951_4.jpg" },
      { idtable: "3-PorROULigh000001", name: "Roleta Relâmpago", img: "images.jpg" },
      { idtable: "3-LightningHindi01", name: "Hindi Lightning Roulette", img: "Hindi-Roulette.jpg" },
      { idtable: "3-GoldVaultRo00001", name: "Gold Vault Roulette", img: "French-Roulette-Gold.jpg" },
      { idtable: "3-k37tle5hfceqacik", name: "Bucharest Auto-Roulette", img: "Bucharest-Roulette.jpg" },
      { idtable: "3-mkvhbciosnfqhat7", name: "Arabic Roulette", img: "Arabic-Roulette.jpg" },
      { idtable: "3-n4jwxsz2x4tqitvx", name: "Japanese Roulette", img: "Japanese-Roulette.jpg" },
      { idtable: "3-r5aw9yumyaxgnd90", name: "Türkçe Rulet", img: "turke.jpg" },
      { idtable: "3-8clwnwrupuvf0osq", name: "Ruleta en Vivo", img: "Ruleta-en-Vivo.jpg" },
      { idtable: "3-PorROU0000000001", name: "Roleta ao Vivo", img: "Ruleta-Bola-Rapida-en-Vivo.jpg" },
      { idtable: "3-qtkjorzrlqeb6hrd", name: "Deutsches Roulette", img: "Deutsches-Roulette.jpg" },
      { idtable: "3-o4vjrhh5rtwimgi3", name: "Greek Roulette", img: "evolution-3-mdkqijp3dctrhnuv-2.jpg" },
      { idtable: "3-zosmk25g2f768o52", name: "Ruletka Live", img: "Ruletka-Live.jpg" },
      { idtable: "3-rr0yhns3we03tqqu", name: "Svensk Roulette", img: "Svensk-Roulette.jpg" },
      { idtable: "3-oqa3v7a2t25ydg5e", name: "Ruleta Bola Rapida en Vivo", img: "Ruleta-Bola-Rapida-en-Vivo.jpg" },
      { idtable: "3-p675txa7cdt6za26", name: "Ruleta en Español", img: "Ruleta-en-Español.jpg" },
      { idtable: "3-7nyiaws9tgqrzaz3", name: "Football Studio Roulette", img: "Football-Studio-Roulette.jpg" },
      { idtable: "3-SpeedAutoRo00001", name: "Speed Auto Roulette", img: "evolution-3-f1f4rm9xgh4j3u2z-2.jpg" },
      { idtable: "3-mtnmxyvdxceqmllz", name: "Suomalainen Ruletti", img: "Suomalainen-Ruletti.jpg" },
      { idtable: "3-pv5q45yjhasyt46y", name: "Emperor Roulette", img: "Emperor-Roulette.jpg" }
      ],
      showModal: false,          // Controls modal visibility
      selectedIds: []            // Temporarily stores selected IDs in modal
    }
  }
    // Show modal
    handleShowModal = () => {
      this.setState({ showModal: true });
    };
  
    // Close modal
    handleCloseModal = () => {
      this.setState({ showModal: false });
    };


  // Handle checkbox change in the modal
  handleCheckboxChange = (id) => {
    this.setState(prevState => {
      const isSelected = prevState.selectedIds.includes(id);

      // If selectedIds already has 2 items and the current ID is not selected, prevent further selection
      if (!isSelected && prevState.selectedIds.length >= 2) {
        toast.error("You can only select 2 tables with your subscription") 
        return prevState; // Return previous state without changes
      }

      // Otherwise, add or remove the ID
      const selectedIds = isSelected 
        ? prevState.selectedIds.filter(item => item !== id) 
        : [...prevState.selectedIds, id];

      return { selectedIds };
    });
  };

  // Add selected tables to idsToShow and close modal
  handleAddTables = () => {


    this.setState(prevState => ({
      idsToShow: [...prevState.idsToShow, ...prevState.selectedIds],
      showModal: false  // Close modal
    }));

    db.collection("users").doc(auth.currentUser.email).set({idsToShow:this.state.selectedIds},{merge:true})

    
  };




  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const sp = query.get('successpay')
        
    setTimeout(() => {
        this.getUser()

        if(sp == 'sus1-M' ||  sp == 'sus1-D') {
          db.collection("users").doc(auth.currentUser.email).set({subscribed:true, idsToShow:[]});
        } else if(sp == 'sus2-M' ||  sp == 'sus2-D'){
          db.collection("users").doc(auth.currentUser.email).set({subscribed:true, idsToShow: this.state.tables?.map(table => table.idtable)});
        }
    
    }, 1000);


  }

  sucessPayment() {
    Swal.fire({
      title: "You have suscribed correctly",
      text:"You have activated your StakeBits subscription, you will now have a weekly session available with your therapist",
      icon:"success"
      })
  }



  getUser = async () => { 
      if(auth.currentUser) {

        if(auth.currentUser.email === "info@stakebits.co") {
           window.location.href = "/admin"
        }



        await db.collection('users').doc(auth.currentUser?.email).get()
        .then(doc => {
            const data = doc.data();

            this.setState(data)
            if(data.subscribed == true) {

              this.setState({ subscribed: true})
            } else {
              this.setState({ subscribed: false})
            }

        })
        } else {
            window.location.href ="/register"
        }
    }



    
  renderTables() {

    const filteredTables = this.state.tables?.filter(table => 
      this.state.idsToShow.includes(table.idtable)
    );



    if(this.state.subscribed == true) {
    return filteredTables.map(item =>   
      <div key={item?.idtable} className="shadow cursor   mt-0  mb-0  m-md-0  border-rounded col-12 col-md-4 col-lg-4 row p-1 rounded gap-3 ">
          <Link to={`/table/${item?.idtable}/${item.name}`} className="border-1 p-2 text-decoration-none">
          <div className="w-100"
            style={{
              backgroundImage: `url(/assets/tables/${item?.img})`, 
              backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2,
              backgroundColor:"#000",
              paddingTop:30,
              height:200,
          }}>
            
          </div>
          <div className="card-body mt-2">
            <h5 className="card-title text-decoration-none">{item?.name}</h5>
          </div>
          <div className="card-footer text-muted text-none">
            Evolution Roulette

          </div>
        </Link>
                                    
      </div>
     )
      } else {
      return (
      <div>
        <div className="text-center mt-5">
           <h3>Suscribed to Stakebits to access to the tables</h3>

           <Link className="btn btn-secondary  btn-lg   mb-3 bold text-white"  to="pircing"><i className="fi-plus me-2" />Suscribe </Link>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>
      )
    }
  
  }

  renderProfile() {
    return (
      <main>
              <div className="container ">
                <h2 className="lh-base mb-0 mt-5 text-dark  ">Roulette tables</h2>
                <span className="mt-2">Live stads Evolution Roulette tables</span>

                <div className="row p-2">
              
                {this.renderTables()}
              
                </div>
            </div>
      <div>


      <div className="container text-center col-6">
      {this.state.idsToShow.length == 0 && this.state.subscribed == true ? 
        <div className="mt-5 mb-5 ">
        <h5>Now that you had subscribed to Stakebit Basic Plan you can add <b>two</b> tables to your account</h5>
        <br/> 
        <Button variant="primary" onClick={this.handleShowModal}>
          Select Tables
        </Button>
        </div>
      : 
      null
      }


      </div>
      
        <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Select Tables</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {this.state.tables.map(table => (
                <Form.Check
                  key={table.idtable}
                  type="checkbox"
                  label={table.name}
                  checked={this.state.selectedIds.includes(table.idtable)}
                  onClick={() => this.handleCheckboxChange(table.idtable)}
                />
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleAddTables}>
              Add Selected
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Page content*/}
      <div className="container   mt-5 ">

          {/* Page content*/}
          <div className="row mt-5 ">
            {/* Sidebar*/}
            <aside className="col-lg-12 col-md-12 pe-xl-4 mb-5 mt-5 ">
              {/* Account nav*/}
              <div className="card card-body  shadow d-flex   pb-1  mt-3 row">
                <div className=" col-4 d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4 mt-2"><img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
                  <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-2">
                    <h3 className=" mb-0 ">Your account</h3>
                    <ul className="list-unstyled fs-sm mt-3 mb-0">
                    <li><a className="nav-link p-0 bold"><i className="fa fa-profile  " />{this.state.name ||auth.currentUser?.displayName}</a></li>
                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />{auth.currentUser?.email}</a></li>
                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />User since  {auth.currentUser?.metadata?.creationTime?.slice(0,16)}</a></li>
                    </ul>
                    
      
                    </div>
                </div>

                 <div className="">


                
                  </div>
                                

              
          
            <br/>
              </div>
          
            </aside>
            {/* Content*/}



          
          </div>
        </div>
      </div>

      </main>
    )
  }

  render() {
      return this.renderProfile()
  }

 }


  export default Profile;