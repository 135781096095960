import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import './Theme.css';

import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Reset from "./Auth/Reset";
import Landing from "./Main/Landing";
import Profile from "./Main/Profile";
import Nosotros from "./Main/Nosotros";


import Precios from "./Components/Precios";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop"
import NavbarComponent from "./Components/Navbar";

import { Toaster } from 'react-hot-toast';

import { auth , db} from "./firebase"
import { useEffect } from "react";

import Terms from "./Main/Terms";
import Table from "./Main/Table";

import AccessAdmin from "./Main/AccessAdmin"
import Refund from "./Main/Refund";

import "react-datepicker/dist/react-datepicker.css";
import AllSpins from "./Main/AllSpins";


function App() {

  useEffect(() => {
    setTimeout(() => {
      if(auth.currentUser === true ) {db.collection("users").doc(auth.currentUser.uid).update({lastStatus: Date.now()})} }, 3000);
  }, [])
  
  return (

    <div className="app">
      <Toaster></Toaster>
      <Router>
      <ScrollToTop/>

      <NavbarComponent/>
        <Switch>

          <Route exact path="/" component={Landing} />   
          <Route exact path="/tables" component={Profile} />        

          <Route exact path="/pircing" component={Precios} />    


          <Route exact path="/about-us" component={Nosotros} />   

          <Route exact path="/register" component={Register}  />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy-policy" component={Terms} />

          

          <Route exact path="/refund-policy" component={Refund} />



          <Route exact path="/reset" component={Reset} />
          <Route exact path="/login" component={Login} />

          <Route exact path="/table/:id/:name" component={Table} />

          <Route exact path="/admin" component={AccessAdmin} />

          <Route exact path="/all-spins/:id/:name" component={AllSpins} />







        </Switch>
        <Footer/>
      </Router>

    </div>
  );
}

export default App;
