import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore

import toast from 'react-hot-toast';
import Swal from 'sweetalert2'


const firebaseConfig = {
  apiKey: "AIzaSyAunvhmA9ReuOupcoL_nCtFKPJVOrA6GzM",
  authDomain: "stakebits-io.firebaseapp.com",
  databaseURL: "https://stakebits-io-default-rtdb.firebaseio.com",
  projectId: "stakebits-io",
  storageBucket: "stakebits-io.appspot.com",
  messagingSenderId: "600529147272",
  appId: "1:600529147272:web:812c9a93a20106ecdfd63d",
  measurementId: "G-W1TBLZXVLT"
};

let app;


if (!firebase.apps.length) {
   app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();

}
const fire = app;
const auth = app.auth();
const db = app.firestore();
const st = app.storage();


const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("users")
      .where("uid", "==", user.email)
      .get();
    // if (query.docs.length === 0) {
    //   await db.collection("users").doc(user.email).set({
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //     therapist: "david@gmail.com",
    //   });
    // }
  } catch (err) {
    toast.error(err.message)
  }
};


const registerInWithGoogle = async (answers,name) => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("users")
      .where("email", "==", user.email)
      .get();
    if (query.docs.length === 0) {

     
    
      await db.collection("users").doc(user.email).set({
        uid: user.uid,
        name: name || user.displayName,
        authProvider: "google",
        email: user.email,
      },{mergeFields:true});


    }
  } catch (err) {
    toast.error(err.message)
  }
};


const signInWithEmailAndPassword = async (email, password) => {

  
  if(email !== "" && password !== "") {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Success login"
      });
   
  } catch (err) {
    toast.error("Error in form, check the fields")
  }}
  else {
    toast.error("Error in form, check the fields")
  }
};

const registerWithEmailAndPassword = async ( email, password,name,answers) => {
  
  var date = Date.now();


  
  if(email !== "" && password !== "" ) {
    if(password.length < 7) {
      toast.error("The password has to be at least 7 characters")
    } else {
  
    try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;


    await db.collection("users").doc(email).set({
      uid: user.uid,
      email,
      creationDate:date,
      lastStatus: date,
      name:name || "",
      description: " ",
    });



  } catch (err) {
    if(err.message === "auth/invalid-password") {
      toast.error("Invalid password")
    } else {
    toast.error("Error in form, check the fields") 
    }
  } }
  } else {
    toast.error("Error in form, check the fields")
  }

};

const sendPasswordResetEmail = async (email) => {
  if(email !== "") {
  try {
    await auth.sendPasswordResetEmail(email);
    toast.success("A password reset link has been sent to your email.")
  } catch (err) {
    toast.error("Error in form, check the fields")
  }}
  else{ 
    toast.error("Error in form, check the fields")
  }
};


const logout = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  Toast.fire({
    icon: "success",
    title: "Session closed"
  });
  auth.signOut();

  setTimeout(() => {
    window.location.href = "/"    
  }, 1000);

};



export {
  auth,
  db,
  st,
  fire,
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
  registerInWithGoogle
};
