import React from "react";


import Preguntas from "./Preguntas"

import LogoWhite from "../assets/logo-white.png"


import { Link  } from "react-router-dom";
import { auth, db } from "../firebase";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";



class Precios extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
      selectedView:"Monthly",
      name: '',
      lastName: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: ''
    }
  } 


  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', this.state);
    this.toggleModal();
  };

  openPrice(url) {
    if(auth.currentUser) {
      this.setState({showModal:true,url})

    } else {
      toast.error("Log in first")
      window.location.href ="/login"
    }
  }

  gotoLink() {

    db.collection("users").doc(auth.currentUser?.email).set(this.state, {merge:true}).then(() =>{
      window.location.href = this.state.url

    })

  }

  renderPrices() {
    if(this.state.selectedView !== "Daily") {
      return (
        <div className=" align-items-center pt-1 row w-100">
        {/* Item */}
        <div className="swiper-slide col-12 col-md-6">
          <div className="card bg-light shadow border overflow-hidden p-0">
            {/* Card header */}
            <div className="card-header border-bottom p-4">
              {/* Icon */}
              
              {/* Title and price */}
              <h6 className="mb-3">Simple plan</h6>
              <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="46€" data-annual-price="46€">434€</span> /Monthly</p>
              <small>Monthly payment</small>
            </div>

            {/* Card body */}
            <div className="card-body p-4 bg-light">
              <div className=" text-lg-start">
                {/* Small title */}
                <h6>Characteristics</h6>
    
                {/* List */}
                <ul className="list-group list-group-borderless mb-2 mb-sm-4 bg-light">
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i>Access to 2 roulette tables 
                  </li>
           
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i> Limited life-time analysis
                  </li>
                
                </ul>

                {/* Button */}
                <a onClick={() => this.openPrice("https://buy.stripe.com/14k17QdNd8ASfoA5kk")} target="__blank" className="btn btn-dark w-100">Buy suscription </a>
              </div>
            </div>
          </div>
        </div>

        {/* Item */}
        <div className="swiper-slide col-12 col-md-6  ">
          <div className="card border border-primary p-0 bg-light">
            {/* Badge */}
            <div className="bg-primary text-white rounded position-absolute top-0 start-50 translate-middle px-2 py-1 ">Best price</div>

              {/* Card header */}
              <div className="card-header  bg-opacity-10 border-bottom border-primary border-opacity-25 p-4">
                {/* Icon */}
              
                {/* Title and price */}
                <h6 className="mb-3">Advance plan</h6>
                <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="$59" data-annual-price="$45">651€</span> /month</p>
                <small>Monthly payment </small>
              </div>

              {/* Card body */}
              <div className="card-body bg-light p-4">
                <div className=" text-start">
                  <h6 className="heading-color">Characteristics</h6>
                  {/* List */}

                  <ul className="list-group bg-light list-group-borderless mb-2 mb-sm-4">
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i>Access to all roulette tables 
                  </li>
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i>Life time stadistics
                  </li>
                
                  </ul>

                  {/* Button */}
                  <a href="https://buy.stripe.com/14k17QdNd8ASfoA5kk" target="__blank" className="btn btn-dark w-100">Buy suscription </a>
                </div>
              </div>
            </div>
        </div>

        {/* Item */}
     
      </div>

      )
    } else {
      return (
        <div className=" align-items-center pt-1 row w-100">
        {/* Item */}
        <div className="swiper-slide col-12 col-md-6">
          <div className="card bg-light shadow border overflow-hidden p-0">
            {/* Card header */}
            <div className="card-header border-bottom p-4">
              {/* Icon */}
              
              {/* Title and price */}
              <h6 className="mb-3">Simple plan</h6>
              <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="46€" data-annual-price="46€">10€</span> /day</p>
              <small>Daily payment</small>
            </div>

            {/* Card body */}
            <div className="card-body p-4 bg-light">
              <div className=" text-lg-start">
                {/* Small title */}
                <h6>Characteristics</h6>
    
                {/* List */}
                <ul className="list-group list-group-borderless mb-2 mb-sm-4 bg-light">
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i>Access to 2 roulette tables 
                  </li>
                 
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i> Limited life-time analysis
                  </li>
                
                </ul>

                {/* Button */}
                <a href="https://buy.stripe.com/14k17QdNd8ASfoA5kk" target="__blank" className="btn btn-dark w-100">Buy suscription </a>
              </div>
            </div>
          </div>
        </div>

        {/* Item */}
        <div className="swiper-slide col-12 col-md-6  ">
          <div className="card border border-primary p-0 bg-light">
            {/* Badge */}
            <div className="bg-primary text-white rounded position-absolute top-0 start-50 translate-middle px-2 py-1 ">Best price</div>

              {/* Card header */}
              <div className="card-header  bg-opacity-10 border-bottom border-primary border-opacity-25 p-4">
                {/* Icon */}
              
                {/* Title and price */}
                <h6 className="mb-3">Advance plan</h6>
                <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="$59" data-annual-price="$45">30€</span> /Day</p>
                <small>Daily payment </small>
              </div>

              {/* Card body */}
              <div className="card-body bg-light p-4">
                <div className=" text-start">
                  <h6 className="heading-color">Characteristics </h6>
                  {/* List */}

                  <ul className="list-group bg-light list-group-borderless mb-2 mb-sm-4">
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i>Access to  all roulette tables 
                  </li>
                  <li className="list-group-item d-flex heading-color mb-0 bg-light">
                    <i className="bi bi-check-lg text-primary me-1"></i>Life time stadistics
                  </li>
                 
                  </ul>

                  {/* Button */}
                  <a href="https://buy.stripe.com/14k17QdNd8ASfoA5kk" target="__blank" className="btn btn-dark w-100">Buy suscription </a>
                </div>
              </div>
            </div>
        </div>

        {/* Item */}
     
      </div>

      )
    }
  }

  render() {

      return (
        <>
        {/* Header END */}
        {/* **************** MAIN CONTENT START **************** */}+  
          <Modal className="modal show fade" tabIndex="-1" role="dialog" show={this.state.showModal} >
                <Modal.Header closeButton>
                  <Modal.Title>Add your information</Modal.Title>
                </Modal.Header>
              <form onSubmit={this.handleSubmit}>
                <div className="modal-body">
                  <div className="form-group m-1">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group m-1">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group m-1">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group m-1">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group m-1">
                    <label>Postal Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      value={this.state.postalCode}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group m-1">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="text-center mb-3  ">
             
                  <button type="submit" onClick={() => this.gotoLink()} className="btn btn-primary">
                    Continue to payment
                  </button>
                </div>
              </form>
        </Modal>
        <main className="mt-5 container">
     
      
        <section className="pt-0">
          {/* Title and switch */}
          <div className="">


            <div className="inner-container-small   ">
              {/* Title */}
            
            <h2 className="  mb-2 ">StakeBits pircing</h2>
            <p>Access to all data from all the tables</p>

            <div className="inner-container text-center mb-4 mb-sm-6">
                  {/* <span className="heading-color bg-light small rounded-3 px-3 py-2">💵 At the best price</span> */}

                </div>
              {/* Switch END */}
            </div>
          </div>  

          {/* Pricing items START */}
          <div className="container mt-n9 text-white">
            {/* Slider START */}
            <div className="swiper text-dark" >
            
            <div className="">

            <span onClick={() => this.setState({selectedView:"Monthly"}) } className={this.state.selectedView == "Monthly" ? 'btn btn-light' : 'btn btn-outline-light'} >Monthly</span>
          
          <span onClick={() => this.setState({selectedView:"Daily"}) } className={this.state.selectedView == "Daily" ? 'btn btn-light mx-2' : 'btn btn-outline-light mx-2'} >Daily</span>
         
            </div>
              <br/> 
              <br/> 
              {/* Slide r items */}
              {this.renderPrices()}
          
              {/* Slider Pagination */}
              <div className="swiper-pagination swiper-pagination-primary position-relative mt-4"></div>
            </div>
            {/* Slider END */}
          </div>
          {/* Pricing items END */}
        </section>
        <Preguntas />
       </main>
      </>
      
      );

    }
  }


export default Precios;
