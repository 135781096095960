import React from "react";

import {  auth, db, logout } from "../firebase";

import {  Modal, ModalBody, Button, ModalHeader, DropdownButton, Form } from 'react-bootstrap';

import NotFound from "../assets/notfound.png";

import toast from 'react-hot-toast';
import { Link } from "react-router-dom/cjs/react-router-dom.min";


  


class AccessAdmin extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
        transactions:[],
        therapists:[],
        name:"",
        description:"",
        retireMethod: "paypal",
        balance:0,
        retireAmount:0,
        retireAccount: null,
        tables: [
          { idtable: "3-7x0b1tgh7agmf6hv", name: "Immersive Roulette", img: "evolution-3-lr6t4k3lcd4qgyrk-2.jpg" },
          { idtable: "3-48z5pjps3ntvqc1b", name: "Auto-Roulette", img: "evolution-3-48z5pjps3ntvqc1b-2.jpg" },
          { idtable: "3-vctlz20yfnmp1ylr", name: "Roulette", img: "Roulette.jpg" },
          { idtable: "3-wzg6kdkad1oe7m5k", name: "VIP Roulette", img: "VIP-Roulette.jpg" },
          { idtable: "3-lkcbrbdckjxajdol", name: "Speed Roulette", img: "Speed-Roulette.jpg" },
          { idtable: "3-LightningSpain01", name: "Ruleta Relámpago en Vivo", img: "Roleta-ao-Vivo.jpg" },
          { idtable: "3-TRLRou0000000001", name: "Türkçe Lightning Rulet", img: "turke.jpg" },
          { idtable: "3-ovfzgqahvicqtiwu", name: "Lightning Roulette Italia", img: "10951_4.jpg" },
          { idtable: "3-PorROULigh000001", name: "Roleta Relâmpago", img: "images.jpg" },
          { idtable: "3-LightningHindi01", name: "Hindi Lightning Roulette", img: "Hindi-Roulette.jpg" },
          { idtable: "3-GoldVaultRo00001", name: "Gold Vault Roulette", img: "French-Roulette-Gold.jpg" },
          { idtable: "3-k37tle5hfceqacik", name: "Bucharest Auto-Roulette", img: "Bucharest-Roulette.jpg" },
          { idtable: "3-mkvhbciosnfqhat7", name: "Arabic Roulette", img: "Arabic-Roulette.jpg" },
          { idtable: "3-n4jwxsz2x4tqitvx", name: "Japanese Roulette", img: "Japanese-Roulette.jpg" },
          { idtable: "3-r5aw9yumyaxgnd90", name: "Türkçe Rulet", img: "turke.jpg" },
          { idtable: "3-8clwnwrupuvf0osq", name: "Ruleta en Vivo", img: "Ruleta-en-Vivo.jpg" },
          { idtable: "3-PorROU0000000001", name: "Roleta ao Vivo", img: "Ruleta-Bola-Rapida-en-Vivo.jpg" },
          { idtable: "3-qtkjorzrlqeb6hrd", name: "Deutsches Roulette", img: "Deutsches-Roulette.jpg" },
          { idtable: "3-o4vjrhh5rtwimgi3", name: "Greek Roulette", img: "evolution-3-mdkqijp3dctrhnuv-2.jpg" },
          { idtable: "3-zosmk25g2f768o52", name: "Ruletka Live", img: "Ruletka-Live.jpg" },
          { idtable: "3-rr0yhns3we03tqqu", name: "Svensk Roulette", img: "Svensk-Roulette.jpg" },
          { idtable: "3-oqa3v7a2t25ydg5e", name: "Ruleta Bola Rapida en Vivo", img: "Ruleta-Bola-Rapida-en-Vivo.jpg" },
          { idtable: "3-p675txa7cdt6za26", name: "Ruleta en Español", img: "Ruleta-en-Español.jpg" },
          { idtable: "3-7nyiaws9tgqrzaz3", name: "Football Studio Roulette", img: "Football-Studio-Roulette.jpg" },
          { idtable: "3-SpeedAutoRo00001", name: "Speed Auto Roulette", img: "evolution-3-f1f4rm9xgh4j3u2z-2.jpg" },
          { idtable: "3-mtnmxyvdxceqmllz", name: "Suomalainen Ruletti", img: "Suomalainen-Ruletti.jpg" },
          { idtable: "3-pv5q45yjhasyt46y", name: "Emperor Roulette", img: "Emperor-Roulette.jpg" }
          ],
        patientsList:[],
        tab:2,
        logins: [], // Array to hold login records
        showModal: false,
        currentUser: { id: null, name: "", email: "" },
        isEditing: false,
        showModalUserTables: false,          // Controls modal visibility
        selectedIds: []            // Temporarily stores selected IDs in modal
  
    }
  } 
  componentDidMount() {
    
    this.getUser()
     this.fetchLogins();
    setTimeout(() => {
        this.getUser()
    }, 1000);
  }


  handleShowModal = (email) => {
    this.setState({  selectedIds: []});
    db.collection("users").doc(email).get().then(doc => {
      var data = doc.data()
      if(data.idsToShow !== undefined) {
        this.setState({selectedIds: data.idsToShow})
        this.setState({ showModalUserTables: true });
      } else {
        this.setState({ showModalUserTables: true ,selectedIds:[]});
      }
    })

  };

  // Close modal
  handleCloseModal = (email) => {
    this.setState({ showModalUserTables: false , selectedIds: []});
  };



  // Add selected tables to idsToShow and close modal
  handleAddTables = () => {


    db.collection("users").doc(this.state.userOpen?.email).set({idsToShow:this.state.selectedIds},{merge:true})
    toast.success("Tables saved")

    this.setState({showModalUserTables:false})
    
    
  };


  async getUser() {

    if(auth?.currentUser?.email == "info@stakebits.co") {

          this.setState({isTherapist: true})
          this.getTransactions()
          this.getUsers()
     
    } 
    else {
      this.setState({isTherapist: false})

  }
};

handleCheckboxChange = (id) => {
  this.setState(prevState => {
    const isSelected = prevState.selectedIds.includes(id);

    // If selectedIds already has 2 items and the current ID is not selected, prevent further selection
    if (!isSelected && prevState.selectedIds.length >= 30) {
      toast.error("You can only select 2 tables with your subscription") 
      return prevState; // Return previous state without changes
    }

    // Otherwise, add or remove the ID
    const selectedIds = isSelected 
      ? prevState.selectedIds.filter(item => item !== id) 
      : [...prevState.selectedIds, id];

    return { selectedIds };
  });
};


addTherapist(newItem) {
  if(newItem.img !== undefined){ 
  this.setState({ therapists: [...this.state.therapists, newItem] })}
}


async fetchLogins() {


  try {
    const querySnapshot = await db.collection("logins").get();
    const loginData = querySnapshot.forEach.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));

    // Update state with fetched data
    this.setState({ logins: loginData, loading: false });
  } catch (error) {
    console.error("Error fetching login data:", error);
    this.setState({ loading: false });
  }
}

openSesion(item) {
  this.setState({modalSesion:true,sesionData:item})

}
addTransaction(newItem) {
  this.setState({ transactions: [...this.state.transactions, newItem] })
}
addUser(newItem) {
    this.setState({ users: [...this.state.users, newItem] })
  }

async getSesions() {
    this.setState({sesions:[]});
    await db.collection("withdraws").get()
    .then(res => {
        res.forEach(doc => {

            let newItem = doc.data();
            this.addS(newItem)

        });
    })
    .catch(err => { console.error(err) });


} 

async getTransactions() {
  this.setState({transactions:[]});
  await db.collection("transactions").get()
  .then(res => {
      res.forEach(doc => {

          let newItem = doc.data();
          newItem.email = doc.id;
          this.addTransaction(newItem)

      });
  })
  .catch(err => { console.error(err) });
}

async getUsers() {
    this.setState({users:[]});
    await db.collection("users").get()
    .then(res => {
        res.forEach(doc => {
  
            let newItem = doc.data();
            newItem.email = doc.id;
            this.addUser(newItem)
  
        });
    })
    .catch(err => { console.error(err) });
  }

openUser(item) {
  this.setState({modalUser:true})
  this.setState({userOpen:item})
}


renderTransaction(item)  {

  return(
    <div className="card   shadow m-2 " >
    <div className="d-flex  align-items-center ">

      <div className="thumbnailCard m-2">
        <img className="rounded-circle" src={item.user?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
        
      </div>
      <div className="mt-1">
      <h5 className=" mb-0 "><b>{item.name}</b>  </h5>
      <h4 className="badge bg-dark ">{item.address}</h4>
    
      </div>
      <div className="mt-1 mx-5 ">
      <h5 className="badge bg-primary mt-1 ">{item.ammount}€</h5>
      
     
      </div>
      
      <a className="btn btn-primary-soft mx-1" href={`https://dashboard.stripe.com/payments/${item.id}`} target="__blank">See on stripe</a>
      
      </div>         
  </div>
  )

}

renderUser(item)  {

    return(
      <div className="card text-white  shadow m-2 ">
      <div className="d-flex  align-items-center justify-content-between ">
  
        <div className="thumbnailCard m-2 d-flex ">
          <img className="rounded-circle" src={item.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
            <div className="text-white mx-3">
        <h5 className=" mb-0 mt-1"><b>{item.email}</b>  </h5>
        {/* <label>Creation date  {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(item.creationDate)}</label> */}
        {item?.subscribed == true ?  <><span class="badge btn btn-success">Subscribed</span> </> : <><span class="badge btn  btn-warning">Not Suscribed</span></>  }


        {item?.banned == true ?  <><span class="mx-2 badge btn btn-danger">Blocked</span> </> : <></>  }
        </div>
        </div>
      

        <div  className="mt-1">
        <div className="btn btn-light cursor m-3" onClick={() => this.openUser(item)} >
            <label className="cursor"><i className="bi bi-eye fa-eye"></i> See account</label>
        </div>
        </div>

     
        </div>         
    </div>
    )
  
  }



renderUsers =  () => {

    var data = this.state.users;

    if(data?.length > 0) {
      return(data.map(item => this.renderUser(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center text-white">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">There is not users</h3>
        </div>
        </>
      )
    }
   
  }


  renderTransactions =  () => {

    var data = this.state.transactions;

    if(data?.length > 0) {
      return(data.map(item => this.renderTransaction(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center text-white">
          <h3 className="text-center">There is not any transactions</h3>
        </div>
        </>
      )
    }
   
  }




  cancelarSesion() {

  }
 

  deleteAccount(email) {
      db.collection("users").doc(email).set({banned:true}, {merge:true})
      this.setState({modalUser:false})
      toast.success("Account block from stakebits")
      setTimeout(() => {
        this.getUsers()
        
      }, 1000);

  }
  unBan(email) {
    db.collection("users").doc(email).set({banned:false}, {merge:true})
    this.setState({modalUser:false})
    toast.success("Account unblock from stakebits")
    setTimeout(() => {
      this.getUsers()
      
    }, 1000);

  }

unSub(email) {
  db.collection("users").doc(email).set({subscribed:false}, {merge:true})
  this.setState({modalUser:false})
  toast.success("Account unBlocked from stakebits")
  setTimeout(() => {
    this.getUsers()
    
  }, 1000);

}


sub(email) {
  db.collection("users").doc(email).set({subscribed:true}, {merge:true})
  this.setState({modalUser:false})
  toast.success("Account subscribed to stakebits")
  setTimeout(() => {
    this.getUsers()
  }, 1000);

}

  handleClose = () => {
    this.setState({
      showModal: false,
      currentUser: { id: null, name: "", email: "" },
      isEditing: false,
    });
  };

  handleShow = (user = { id: null, name: "", email: "" }) => {
    this.setState({
      showModal: true,
      currentUser: user,
      isEditing: !!user.id,
    });
  };

  handleSave = async () => {
    const { currentUser, isEditing } = this.state;

    try {
      if (isEditing) {
        // Update existing user
        await db.collection("users").doc(currentUser.id).update({
          name: currentUser.name,
          email: currentUser.email,
        })
       
      } else {
        await db.collection("users").doc(currentUser.id).set({
          name: currentUser.name,
          email: currentUser.email,
        })
      }

      // Close the modal and reset state
      this.handleClose();
    } catch (error) {
      console.error("Error saving user: ", error);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        [name]: value,
      },
    }));
  };

  renderProfile(){
    const { users, showModal, currentUser, isEditing } = this.state;

    if(!this.state?.isTherapist) {
      return (
        <>
        	<section className="pb-0 ">
		    	<div className=""
		    	style={{
		    	height:'480px',
		    	backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
		    	>
		    	<div className=" container " >
					<div className="container p-3">
						<h1 className="lh-base mb-0 mt-5 text-white">Admin access</h1>
            <h4 className="text-white">You have to be authorised to access this section</h4>

        
            <br/>
            <br/>
            <Link to="/login" className="btn btn-light" type="dark" color="light" >
            Login
            </Link>
					</div>
				</div>
				</div>

	  	</section>
    </>
      )
    } else {
      return (
        <>
        <div className="h-400 d-block d-lg-none d-md-none d-flex justify-content-center align-items-center  ">
            <h3>Use your PC to enter the Admin pannel</h3>
        </div>
       <main className="mt-4 mb-5 d-none d-lg-block d-md-block">
      {/* Modal retire more  */}

      <Modal show={this.state.modalUser == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">User profile</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalUser:false})} />
        </ModalHeader>
        <ModalBody className="">

      <div className="card card-body  shadow   mt-1">
      <div className="d-flex d-md-block d-lg-flex align-items-start 4 mt-1">
        
        <img className="rounded-circle" src={this.state.openUser?.img || "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
        <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-0">
        {/* <span className="badge badge-pill bg-dark mb-0 ">{this.state.therapi
            st?.} </span>  */}
        <h4 className=" mb-0 ">{this.state.userOpen?.email} </h4> 

          
        <h5 className="text-muted mt-3">Name</h5>

        <h4 className=" mb-2 ">{this.state.userOpen?.name} </h4> 
        
          <h5 className="text-muted mt-3">Address information</h5>

          <p className=" mb-2 ">{this.state.userOpen?.address} </p>  
          <p className=" mb-2 ">{this.state.userOpen?.postalcode} </p> 
          <p className=" mb-2 ">{this.state.userOpen?.city} </p> 


          
          <br/>      <br/>
          <h5>Account information</h5>

          Creation date  {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(this.state.userOpen?.creationDate)}
          <br/>
          <br/>
          {this.state.userOpen?.subscribed == true ?  <>Subscribed </> : <>Not subscribed</>  }
          <div>


        <br/>
        </div>
        </div>
      </div>

    </div>
        
      { this.state.userOpen?.banned != true ?
       <div className="btn btn-danger m-3   cursor" onClick={() => this.deleteAccount(this.state?.userOpen.email)} >
            <label className=" cursor">Block account </label>
        </div>
      : 
      <div className="btn btn-success m-3   cursor" onClick={() => this.unBan(this.state?.userOpen.email)} >
        <label className=" cursor">Unblock account </label>
      
      </div>}


      { this.state.userOpen?.subscribed == true ?
        <div className="btn btn-warning m-3   cursor" onClick={() => this.unSub(this.state?.userOpen.email)} >
        <label className=" cursor">Unsubscribed account </label>
      
      </div>
      : 
        <>
           <div className="btn btn-warning m-3   cursor" onClick={() => this.sub(this.state?.userOpen.email)} >
              <label className=" cursor">Subscribe account </label>   
          </div>
        </>}



      <Button variant="primary" className="" onClick={() => this.handleShowModal(this.state.userOpen?.email)}>
          Manage Tables
        </Button>
        </ModalBody>


        <Modal.Footer>

      </Modal.Footer>
      </Modal>

      <Modal show={this.state.showModalUserTables} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Select Tables</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {this.state.tables.map(table => (
                <Form.Check
                  key={table.idtable}
                  type="checkbox"
                  label={table.name}
                  checked={this.state.selectedIds.includes(table.idtable)}
                  onClick={() => this.handleCheckboxChange(table.idtable)}
                />
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleAddTables}>
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      {/* Modal retire money end */}
      {/* Container START */}
      <div className="container mt-5 text-white">
        <div className="row mt-3">
        <div className="col-lg-3 col-md-3  p text-white ">
            {/* Advanced filter responsive toggler START */}
            {/* Divider */}
           
            {/* Advanced filter responsive toggler END */}
            <nav className="navbar navbar-light navbar-expand-lg mx-0 text-white">
              <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasNavbar">

                <div className="offcanvas-header">
                  <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>

                <div className="offcanvas-body p-0">

                  <div className="card w-100">

                    <div className="card-body">

                      <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">

                   

                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a className={this.state.tab === 2 ?"nav-link d-flex mb-0 active text-white":"nav-link d-flex mb-0 text-white"} href="#nav-setting-tab-1" data-bs-toggle="tab"><span>Users </span></a>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a className={this.state.tab === "card" ?"nav-link d-flex mb-0 active text-white":"nav-link d-flex mb-0 text-white"} href="#nav-setting-tab-2" data-bs-toggle="tab"><span>Stadistics </span></a>
                        </li>

                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a href="#nav-setting-tab-3" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 text-white "}><span>Transactions </span></a>
                        </li>
    
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a href="#nav-setting-tab-4" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "}><span>Tables </span></a>
                        </li>
                       
                    
                      </ul>

                    </div>


                    <div className="card-footer text-center py-2">
                      <a className="btn btn-link text-danger btn-sm" onClick={() => logout()}>Logout</a>
                    </div>
                  </div>

                </div>

              </div>
            </nav>
          </div>
            {/* Main content START */}
            <div className="col-lg-6   vstack text-white ">
            {/* Setting Tab content START */}
            <div className="tab-content py-0 mb-0  p-0  m-0 mt-0 ">

            <div  className={this.state.tab !== "card" ?"tab-pane show active fade":"tab-pane fade"}  id="nav-setting-tab-1">

                        
          <div className="card mb-4 text-white">

            <div className="card-header border-0 pb-0">
              <h1 className="h5 card-title text-white">Users </h1>
              <p className="mb-0 text-white">All the users </p>
              {/* <Button type="secondary" icon="plus" onClick={this.handleShow} className="mt-3">Add user</Button> */}
            </div>

            <div className="card-body">

              {this.renderUsers()}

            </div>

          </div>




          </div> 

         
            <div  className={this.state.tab == "card" ?"tab-pane show active fade":"tab-pane fade"}  id="nav-setting-tab-2">
                {/* Card START */}
                
                <div className=" text-white">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 text-white ">
                    <div className="row">
                    <div className="col-md-12 col-12 col-md-6 mb-3">
                      <h5 className="card-title text-white">Stadistics</h5>
                      <p className="mb-0"></p>
                  <div className="container mt-5">
                    <div className="row text-center">
                      {/* Total Users Card */}
                      <div className="col-md-4">
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <i className="fas fa-users fa-3x text-primary mb-3"></i>
                            <h5 className="card-title text-white">Total Users</h5>
                            <p className="card-text text-white fs-4">{12}</p>
                          </div>
                        </div>
                      </div>

                      {/* Registered Users Card */}
                      <div className="col-md-4">
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <i className="fas fa-user-check fa-3x text-success mb-3"></i>
                            <h5 className="card-title text-white">Login Users</h5>
                            <p className="card-text fs-4 text-white">{3}</p>
                          </div>
                        </div>
                      </div>

                      {/* Website Views Card */}
                      <div className="col-md-4">
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <i className="fas fa-eye fa-3x text-info mb-3"></i>
                            <h5 className="card-title text-white">Website Views</h5>
                            <p className="card-text fs-4 text-white">{232}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                                  </div>

                    </div>
                  
                 
                  </div>
                  <div className="">
                  <div className="container mt-4">
                  <h2 className="mb-4">Login Users</h2>

                    {this.state?.loading ? (
                      <p>Loading...</p>
                    ) : (
                      <ul className="list-group">
                        {this.state.logins.map((login) => (
                          <li key={login.id} className="list-group-item">
                            <strong>{login.email}</strong> - Logged in at:{" "}
                            {login.loginTime?.toDate().toLocaleString()}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                              </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                </div>

                
              </div>

    
         
             

              <div className="tab-pane fade" id="nav-setting-tab-3">
                {/* Card START */}
                
                <div className="card text-white">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <h5 className="card-title">Transactions</h5>
                      <p className="mb-3  ">All the transactions </p>
                    </div>

                    </div>
                  </div>
                  </div>

                  <div className="card mt-3">
                  <div className="card-body">
                  {this.renderTransactions()}
                  </div>
                  
                  </div>
              </div>


              <div className="tab-pane fade" id="nav-setting-tab-3">
                {/* Card START */}
                
                <div className="card">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <h5 className="card-title">Therapists</h5>
                      <p className="mb-3  ">All the therapists</p>
                    </div>

                    </div>
                  </div>
                  </div>

                  <div className="card mt-3">
                  <div className="card-body">

                  </div>
                  
                  </div>
              </div>
              
              <div  className="tab-pane fade"  id="nav-setting-tab-4">
                {/* Card START */}
                
                <div className="">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-md-6 col-12 col-md-6 mb-3">
                      <h5 className="card-title text-white">Tables</h5>
                      <p className="mb-0"></p>
                    </div>

                    </div>
                    <span className="mt-2">Live stads Evolution Roulette tables</span>

                <div className="row p-2">
                {this.state.tables?.map(item => 
                  
                  <div key={item?.idtable} className="shadow cursor   mt-0  mb-0  m-md-0  border-rounded col-12 col-md-4 col-lg-4 row p-1 rounded gap-3 ">
                      <a className="border-1 p-2 text-decoration-none">
                      <div className="w-100"
                        style={{
                          backgroundImage: `url(/assets/tables/${item?.img})`, 
                          backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2,
                          backgroundColor:"#000",
                          paddingTop:30,
                          height:200,
                      }}>
                        
                      </div>
                      <div className="card-body mt-2">
                        <h5 className="card-title text-decoration-none">{item?.name}</h5>
                      </div>

                       
                      <div className="card-footer text-muted text-none">
                      <div class="btn-group mt-2 mb-2">
                      <DropdownButton title="Actions">
                      <a class="dropdown-item" target="_blank"  href={`https://stakebits.co/all-spins/${item?.idtable}/${item.name}`} >See all spins</a>
                      {/* <a class="dropdown-item text-danger" href="#"> <i class="bi bi-eye"></i> Hide table</a>  */}
                      <a   target="_blank" href={`https://stakebits.co/table/${item?.idtable}/${item.name}`}  class="dropdown-item">
                            See table
                          </a>
                                        
                        </DropdownButton>
                        </div>
                        <a   target="_blank" href={`https://stakebits.co/table/${item?.idtable}/${item.name}`}  class="btn btn-light mx-3">
                            See table
                          </a>

                      </div>
                    </a>
                                                
                          </div>
                 )}
              
                </div>
                 
                  </div>
                  <div className="h-200">

                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                </div>

                
              </div>

    

           
            </div>
          </div>
        
        </div> {/* Row END */}
      </div>
    </main>

        </>
      )
    }
  }

  render() {
      return this.renderProfile()
    }
} 
  


export default AccessAdmin
