import React from "react";


import Compromiso from "../assets/icons/gambler.png"
import Profesionales from "../assets/icons/roulette.png"


class Landing extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
    }
  } 



  render() {

      return (
		<div>
    <section className="pb-0">
        <div className="row mb-3 mb-xl-0 bg"
            style={{
            height:'130px',
            // backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', 
            backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
            >
        <div className=" container" >
            <div className="container ">
                <h1 className="lh-base mb-0 mt-5 text-dark italic ">Stake bit stadistics</h1>
            </div>
        </div>
        </div>
    <div className="container">
        {/* Main title */}
    

        {/* About detail START */}
        <div className="row">
            {/* Content */}
            <div className="col-lg-6 pe-lg-5 mt-5">
                <h2>
                    <img src={Profesionales} width="50px" />
                 </h2>
                <h3>Our data</h3>
                <p className="mb-5"> Statistics include a variety of data, such as the frequency of numbers, colors, dozens, neighbors on the roulette wheel, sectors, and much more. The tracker interface ensures transparency and openness, allowing players to make more informed decisions based on the provided information.</p>

            </div>

            <div className="col-lg-6 pe-3  mt-5 ">
                <h2>
                    <img src={Compromiso} width="50px" />
                 </h2>
                <h3>Roulette Evolution</h3>
                <p className="mb-5">We work with all tables of Evolution roulette provider</p>

            </div>


        


            {/* Image */}
            <div className="col-lg-5 mt-7 mt-lg-0">
                <div className="position-relative d-flex justify-content-center">
                    {/* Hero image */}


                    {/* Trustpilot review START */}

                </div>
            </div>
        </div>

        {/* About detail END */}
    </div>	
</section>
 <br/>
      <br/>
      <br/>
      <div className="container">
      <h4>Statistics and real-time roulette tracking</h4>
        In this section, players are given a unique opportunity to follow live statistics of roulette games from leading developers such as Evolution, Ezugi, Pragmatic, and Playtech.

        Statistics include a variety of data, such as the frequency of numbers, colors, dozens, neighbors on the roulette wheel, sectors, and much more. The tracker interface ensures transparency and openness, allowing players to make more informed decisions based on the provided information.

        Such a tracker is a useful tool for analyzing trends and patterns in games, which can help players develop their strategies and increase their chances of success. All this creates an engaging and interactive experience for gambling enthusiasts, providing them with access to important real-time data.
        <br/>
      <br/>
      <br/>
      <br/>


      <h2>About our Stats</h2>
    <p>
        Looking to find the latest statistics and results from the Immersive Roulette live game from Evolution Gaming? You will be able to find all this and any information you might need right here.
    </p>

    <h2>Spin History</h2>
    <p>
        This section is dedicated to the spin history of the game, giving you all relevant information such as the spin result, the dealer and the number type. You are also able to sort by the day/time the spin was finished and can look through hundreds of past spins.
    </p>

    <h2>Temperature</h2>
    <p>
        The Temperature section gives you an overview of which numbers are hot and cold. In this section you will be able to see the hit frequency of both hot and cold numbers which will be based on the past hours and hundreds of spins. The hit percentage will also be shown under each of the numbers listed.
    </p>

    <h2>Tier Details</h2>
    <p>
        The roulette wheel is sectioned off into four different tiers, which are made up of Orphans, Neighbours of Zero, Third of the wheel and Zero game. In this section you will be seeing a visual of these tiers, also allowing you to see which numbers fall under each tier and also the hit frequency of these different tiers during the past 298 spins.
    </p>
    <ul>
        <li><strong>Orphans</strong> – covers 2 segments of the wheel including numbers 17, 34, 6, 1, 20, 14, 31 and 9.</li>
        <li><strong>Neighbours of Zero</strong> – covers two segments of the wheel on either side of the zero game tier including numbers 22, 18, 29, 7, 28, 19, 4, 21, 2 and 25.</li>
        <li><strong>Third of the wheel</strong> – covers a third of the wheels numbers including 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16 and 33.</li>
        <li><strong>Zero game</strong> – covers the smallest segment of the wheel including the numbers closest to the zero and includes numbers 12, 35, 3, 26, 0, 32 and 15.</li>
    </ul>
       </div>
</div>
      
      );

    }
  }


export default Landing;
