export default function Preguntas() {
    return (
      <section>
        <div className="container align-items-center mt-5 mb-5">
          <h2 className="fs-1 mb-2 text-center">Frequently Asked Questions</h2>
          <p className="mb-4 text-center">

          </p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="accordion col-md-10 text-left" id="accordionExample">
            <div className="accordion-item shadow m-4 text-left">
  <h2 className="accordion-header" id="headingOne">
    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What types of roulette can I find statistics for on this website?</button>
  </h2>
  <div className="accordion-collapse collapse show text-left" aria-labelledby="headingOne" data-bs-parent="#accordionExample" id="collapseOne">
    <div className="accordion-body  text-left">Our website features statistics for various types of roulette, including American, European, and French roulette.</div>
  </div>
</div>

<div className="accordion-item shadow m-4 text-left">
  <h2 className="accordion-header" id="headingTwo">
    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How are the roulette statistics updated?</button>
  </h2>
  <div className="accordion-collapse collapse text-left" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" id="collapseTwo">
    <div className="accordion-body  text-left">The statistics are updated in real-time, reflecting the latest results from our partnered casinos.</div>
  </div>
</div>


<div className="accordion-item shadow m-4 text-left">
  <h2 className="accordion-header" id="headingFour">
    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">What specific statistics are available for roulette?</button>
  </h2>
  <div className="accordion-collapse collapse text-left" aria-labelledby="headingFour" data-bs-parent="#accordionExample" id="collapseFour">
    <div className="accordion-body  text-left">We provide data on hot and cold numbers, overall winning percentages, frequency of specific bets, and historical trends.</div>
  </div>
</div>

<div className="accordion-item shadow m-4 text-left">
  <h2 className="accordion-header" id="headingFive">
    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Is there a mobile version of the casino stats website?</button>
  </h2>
  <div className="accordion-collapse collapse text-left" aria-labelledby="headingFive" data-bs-parent="#accordionExample" id="collapseFive">
    <div className="accordion-body  text-left">Yes! Our website is fully optimized for mobile devices, allowing you to access roulette statistics on the go.</div>
  </div>
</div>

            </div>
          </div>
      
          <div className="text-center">
            <a href="mailto:info@stakebits.co">Need help? <span className="cursor text-decoration-underline">Contact us here</span></a>
          </div>
        </div>
      </section>
)
} 