import React, { useEffect , useState} from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import Logo from "../assets/logo.png"
import { logout ,auth} from "../firebase";

import { Link } from "react-router-dom/cjs/react-router-dom";

function Footer() {
    
    const [user, loading] = useAuthState(auth);
    const [LogButton, setLogButton] = useState(false);

    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
      if (user)
       { setLogButton(false) } else {
         setLogButton(true)
       }
    }, [user, loading]);




    return (
      <>
     <footer className="pt-5">
          <div className="container">
            {/* Row START */}
            <div className="row g-3">
              {/* Widget 1 START */}
              <div className="col-lg-3">
                {/* logo */}
                <a className="me-0" >
                </a>
                <p className="my-3">
                <Link to="/" className="navbar-brand  " >
                <img src={Logo} width={40} height={37}/>
                 <b className="font-primary mx-2"> StakeBits</b>
              </Link>
                </p>
                Statistics and real-time roulette tracking
      
                {/* Social media icon */}
                <ul className="list-inline mb-0 mt-3">
           
                </ul>
              </div>
              {/* Widget 1 END */}
              {/* Widget 2 START */}
              <div className="col-lg-6 col-12 ">
                <div className="row">
                  {/* Link block */}
                  <div className="col-6 col-md-6">
                    <h5 className="mb-2 mb-md-4">Links</h5>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link className="nav-link" to="/about-us">
                         About us
                        </Link>
                      </li>
                  

                      
                    <li className="nav-item">
                        
                      </li>



                      <li className="nav-item">
                        <Link className="nav-link" to="/admin">
                          Enter as admin
                        </Link>
                      </li>


                      {LogButton ?
                      <li className="nav-item">
                        <Link className="nav-link" to="/register">
                        Account
                        </Link>
                      </li>
                      : 
                      <li className="nav-item">
                        <a className="nav-link text-danger cursor" onClick={() => logout()} >
                          Log out
                        </a>
                        </li>
                    }

                    </ul>
                  </div>

                  <div className="col-lg-4 col-12">
                  <h5 className="mb-2 mb-md-4">More </h5>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link className="nav-link" to="/privacy-policy">
                            Privacy policy
                        </Link>
                      </li>
                  
                      <li className="nav-item"> 
                        <Link className="nav-link" to="/terms">
                        Terms of conditions
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/refund-policy">
                             Refund policies
                        </Link>
                      </li>

                    </ul>

                  </div>
                  {/* Link block */}
              
                  {/* Link block */}
               
                </div>
              </div>
              {/* Widget 2 END */}
              {/* Widget 3 START */}
              <div className="col-lg-3">
                <h5 className="mb-2 mb-md-4">Contact</h5>
                {/* Time */}
                <p className="mb-0">
                  Email:<span className="h6 fw-light ms-2">info@stakebits.co</span>
                </p>
                <div className="row g-2 mt-2">
                  {/* Google play store button */}
                  <div className="col-6 col-sm-4 col-md-3 col-lg-6">
                    <a href="#">
                      {" "}
                      <img src="assets/images/client/google-play.svg" alt="" />{" "}
                    </a>
                  </div>
                  {/* App store button */}
                 
                </div>{" "}
                {/* Row END */}
              </div>
              {/* Widget 3 END */}
            </div>
            {/* Row END */}
            {/* Divider */}
            <hr className="mt-4 mb-0" />
            {/* Bottom footer */}
            <div className="py-3">
              <div className="container px-0">
                <div className="d-lg-flex justify-content-between align-items-center py-3 text-center text-md-left">
                  {/* copyright text */}
                  <div className="text-primary-hover color-light">
                    {" "}
                    Copyrights{" "}
                    <a
                      href="https://www.stakebits.co/"
                      target="_blank"
                      className="text-body text-light text-dark text-decoration-none"
                    >
                      © 2024 Stakebits
                    </a>
                    .{" "} All rights reserved.{" "}
                  </div>
                  {/* copyright links*/}
                  <div className="justify-content-center mt-3 mt-lg-0">
                    <ul className="nav list-inline justify-content-center mb-0">
                      <li className="list-inline-item">
                        {/* Language selector */}
                        <div className="dropup mt-0 text-center text-sm-end">
                       
                        </div>
                      </li>
                      <li className="list-inline-item">
                        <a className="nav-link" href="/terms">
                          Privacy terms
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="nav-link pe-0" href="/terms">
                          Terms and conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  

}

export default Footer;
