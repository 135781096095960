import React from "react";

import { Link  } from "react-router-dom";

import Preguntas from "../Components/Preguntas";


  
const rouletteGames = [
  {
    "idtable": "3-7x0b1tgh7agmf6hv",
    "name": "Immersive Roulette",
    "img": "evolution-3-lr6t4k3lcd4qgyrk-2.jpg"
  },
  // {
  //   "idtable": "3-XxxtremeLigh0001",
  //   "name": "XXXtreme Lightning Roulette"
  // },
  // {
  //   "idtable": "3-LightningTable01",
  //   "name": "Lightning Roulette"
  // },
  {
    "idtable": "3-48z5pjps3ntvqc1b",
    "name": "Auto-Roulette",
    "img":"evolution-3-48z5pjps3ntvqc1b-2.jpg",
  },
  {
    "idtable": "3-vctlz20yfnmp1ylr",
    "name": "Roulette",
    "img": "Roulette.jpg"
  },
  {
    "idtable": "3-wzg6kdkad1oe7m5k",
    "name": "VIP Roulette",
    "img": "VIP-Roulette.jpg"
  },
  // {
  //   "idtable": "3-01rb77cq1gtenhmo",
  //   "name": "Auto-Roulette VIP"
  // },
  {
    "idtable": "3-f1f4rm9xgh4j3u2z",
    "name": "Auto-Roulette La Partage",
     "img": "Roulette.jpg"
  },
  {
    "idtable": "3-lkcbrbdckjxajdol",
    "name": "Speed Roulette",
    "img": "Speed-Roulette.jpg"
  },
  {
    "idtable": "3-LightningSpain01",
    "name": "Ruleta Relámpago en Vivo",
    "img":"Roleta-ao-Vivo.jpg"
  },
  {
    "idtable": "3-TRLRou0000000001",
    "name": "Türkçe Lightning Rulet",
    "img":"turke.jpg",
  },
  {
    "idtable": "3-ovfzgqahvicqtiwu",
    "name": "Lightning Roulette Italia",
    "img":"10951_4.jpg",
  },
  {
    "idtable": "3-PorROULigh000001",
    "img": "images.jpg",
    "name":"Roleta Relâmpago",
  },
  {
    "idtable": "3-LightningHindi01",
    "name": "Hindi Lightning Roulette",
    "img":"Hindi-Roulette.jpg",
  },
  {
    "idtable": "3-GoldVaultRo00001",
    "name": "Gold Vault Roulette",
    "img":"French-Roulette-Gold.jpg",
  },
  {
    "idtable": "3-k37tle5hfceqacik",
    "name": "Bucharest Auto-Roulette",
    "img":"Bucharest-Roulette.jpg"
  },
  // {
  //   "idtable": "3-mdkqijp3dctrhnuv",
  //   "name": "Salon Privé Roulette"
  // },
  {
    "idtable": "3-mkvhbciosnfqhat7",
    "name": "Arabic Roulette",
    "img": "Arabic-Roulette.jpg"
  },
  {
    "idtable": "3-n4jwxsz2x4tqitvx",
    "name": "Japanese Roulette",
    "img": "Japanese-Roulette.jpg"
  },
  {
    "idtable": "3-r5aw9yumyaxgnd90",
    "name": "Türkçe Rulet",
    "img": "turke.jpg"
  },
  {
    "idtable": "3-8clwnwrupuvf0osq",
    "name": "Ruleta en Vivo",
    "img": "Ruleta-en-Vivo.jpg"
  },
  {
    "idtable": "3-PorROU0000000001",
    "name": "Roleta ao Vivo",
    "img": "Ruleta-Bola-Rapida-en-Vivo.jpg"
  },
  {
    "idtable": "3-qtkjorzrlqeb6hrd",
    "name": "Deutsches Roulette",
    "img": "Deutsches-Roulette.jpg"
  },
  {
    "idtable": "3-o4vjrhh5rtwimgi3",
    "name": "Greek Roulette",
    "img":"evolution-3-mdkqijp3dctrhnuv-2.jpg",
  },
  {
    "idtable": "3-zosmk25g2f768o52",
    "name": "Ruletka Live",
    "img": "Ruletka-Live.jpg"
  },
  {
    "idtable": "3-rr0yhns3we03tqqu",
    "name": "Svensk Roulette",
    "img": "Svensk-Roulette.jpg"
  },
  {
    "idtable": "3-oqa3v7a2t25ydg5e",
    "name": "Ruleta Bola Rapida en Vivo",
    "img": "Ruleta-Bola-Rapida-en-Vivo.jpg"
  },
  {
    "idtable": "3-p675txa7cdt6za26",
    "name": "Ruleta en Español",
    "img": "Ruleta-en-Español.jpg"
  },
  // {
  //   "idtable": "3-lr6t4k3lcd4qgyrk",
  //   "name": "Grand Casino Roulette"
  // },
  // {
  //   "idtable": "3-pnkk4iuchw7blb2p",
  //   "name": "Dragonara Roulette"
  // },
  {
    "idtable": "3-7nyiaws9tgqrzaz3",
    "name": "Football Studio Roulette",
    "img": "Football-Studio-Roulette.jpg"
  },
  {
    "idtable": "3-SpeedAutoRo00001",
    "name": "Speed Auto Roulette",
    "img":"evolution-3-f1f4rm9xgh4j3u2z-2.jpg",
  },
  // {
  //   "idtable": "3-mrpuiwhx5slaurcy",
  //   "name": "Hippodrome Grand Casino"
  // },
  // {
  //   "idtable": "3-mvrcophqscoqosd6",
  //   "name": "Casino Malta Roulette"
  // },
  {
    "idtable": "3-mtnmxyvdxceqmllz",
    "name": "Suomalainen Ruletti",
    "img": "Suomalainen-Ruletti.jpg"
  },
  {
    "idtable": "3-pv5q45yjhasyt46y",
    "name": "Emperor Roulette",
    "img": "Emperor-Roulette.jpg"
  }
]
;
class Landing extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
    }
  } 

  
  
  componentDidMount() {
  }


  render() {

      return (
        <>
        <main className="">
          <section className="position-relative overflow-hidden  pt-lg-3 " id="testfade" 
          
          style={{
            height:'600px',
            // backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', 
            backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
          >

            <div className="container mt-5" > 
              {/* Title */}
              <div className="row align-items-center g-5">
                {/* Left content START */}
                <div className="col-lg-5 col-xl-5 position-relative z-index-1 text-center text-lg-start mb-5 mb-sm-0 text-white">
                 
                  {/* Title */}

                  <h1 className="mb-0 display-4 text-white">
                  Statistics, tracking, and results of online roulette
                    <span className="position-relative">
                        
</span>
</h1>
{/* Content */}
<h3 className="my-4 lead text-white ">
Detailed real-time statistics on online roulettes from Evolution
</h3>

<div className="d-sm-flex align-items-center justify-content-center justify-content-lg-start">
  {/* Button */}
  <Link
    to="/tables"
    className="btn btn-lg btn-primary round me-2 mb-4 mb-sm-0"
  >
      Check all the tables
  </Link>

  {/* Video button */}
</div>
<div className="d-flex text-center justify-content-center">
{/* <a className="text-center mt-3 width-100">Start your sessions with promotions up to 50%</a> */}
</div>

</div>
{/* Left content END */}
{/* Right content START */}
<div className="col-lg-7 col-xl-6 text-center position-relative">
  <img src={"https://yggdrasilgaming.com/w/files/2022/02/RouletteEvolution-Single-Game-Logo-750x540px.png"}
  height={500}></img>
  {/* SVG decoration */}

</div>
{/* Right content END */}
</div>
</div>
{/* Content END */}
</section>

 
          <div className=" container " >
            <div className="container ">
                <h2 className="lh-base mb-0 mt-5 text-dark  ">Roulette tables</h2>
                <span className="mt-2">Live stads Evolution Roulette tables</span>

                <div className="row p-lg-2 p-0 justify-content-center">
                {rouletteGames.slice(0,6).map(item => 
                          <div key={item?.idtable} className="shadow cursor   mt-0  mb-0  m-md-0  border-rounded col-12 col-md-4 col-lg-4 row p-1 rounded gap-3 ">
                          <Link to={`/table/${item?.idtable}/${item.name}`} className="border-1 p-2 text-decoration-none">
                          <div className="w-100"
                            style={{
                              backgroundImage: `url(/assets/tables/${item?.img})`, 
                              backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2,
                              backgroundColor:"#000",
                              paddingTop:30,
                              height:200,
                          }}>
                            
                          </div>
                          <div className="card-body mt-2">
                            <h5 className="card-title text-decoration-none">{item?.name}</h5>
                          </div>
                          <div className="card-footer text-muted text-none">
                            Evolution Roulette
                          </div>
                        </Link>
                                                    
                              </div>
                 )}
              
                </div>
            </div>
              
	    </div> 
      <h4><Link to="/tables" className="link text-center d-flex aling-items-center justify-content-center mt-5">See all tables</Link></h4>

         
      
      <section className="pt-0 container mt-5">
          {/* Title and switch */}
          <div className="container">


            <div className="inner-container-small  mt-5 ">
              {/* Title */}
            
            <h2 className="  mb-2 ">StakeBits pircing</h2>
            <p>Access to all data from all the tables</p>

            <div className="inner-container text-center mb-4 mb-sm-6">
                  {/* <span className="heading-color bg-light small rounded-3 px-3 py-2">💵 At the best price</span> */}

                </div>
              {/* Switch END */}
            </div>
          </div>  

          {/* Pricing items START */}
          <div className="container mt-n9 text-dark">
            {/* Slider START */}
            <div className="swiper text-dark" >

              {/* Slider items */}
              <div className=" align-items-center pt-1 row w-100">
                {/* Item */}
                <div className="swiper-slide col-12 col-md-6">
                  <div className="card bg-light shadow border overflow-hidden p-0">
                    {/* Card header */}
                    <div className="card-header border-bottom p-4">
                      {/* Icon */}
                      
                      {/* Title and price */}
                      <h6 className="mb-3">Simple plan</h6>
                      <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="46€" data-annual-price="46€">10€</span> /month</p>
                      <small>Monthly payment</small>
                    </div>

                    {/* Card body */}
                    <div className="card-body p-4 bg-light">
                      <div className=" text-lg-start">
                        {/* Small title */}
                        <h6>Characteristics</h6>
            
                        {/* List */}
                        <ul className="list-group list-group-borderless mb-2 mb-sm-4 bg-light">
                          <li className="list-group-item d-flex heading-color mb-0 bg-light">
                            <i className="bi bi-check-lg text-primary me-1"></i>Access to roulette tables 
                          </li>
                          <li className="list-group-item d-flex heading-color mb-0 bg-light">
                            <i className="bi bi-check-lg text-primary me-1"></i>Check 2 months stadistics
                          </li>
                          <li className="list-group-item d-flex heading-color mb-0 bg-light">
                            <i className="bi bi-check-lg text-primary me-1"></i> Limited life-time analysis
                          </li>
                        
                        </ul>

                        {/* Button */}
                        <Link to="/login" className="btn btn-dark w-100">Buy suscription </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Item */}
                <div className="swiper-slide col-12 col-md-6  ">
                  <div className="card border border-primary p-0 bg-light">
                    {/* Badge */}
                    <div className="bg-primary text-white rounded position-absolute top-0 start-50 translate-middle px-2 py-1 ">Best price</div>

                      {/* Card header */}
                      <div className="card-header  bg-opacity-10 border-bottom border-primary border-opacity-25 p-4">
                        {/* Icon */}
                      
                        {/* Title and price */}
                        <h6 className="mb-3">Premium plan</h6>
                        <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="$59" data-annual-price="$45">20€</span> /month</p>
                        <small>Monthly payment </small>
                      </div>

                      {/* Card body */}
                      <div className="card-body bg-light p-4">
                        <div className=" text-start">
                          <h6 className="heading-color">Características suscripción</h6>
                          {/* List */}

                          <ul className="list-group bg-light list-group-borderless mb-2 mb-sm-4">
                          <li className="list-group-item d-flex heading-color mb-0 bg-light">
                            <i className="bi bi-check-lg text-primary me-1"></i>Access to roulette tables 
                          </li>
                          <li className="list-group-item d-flex heading-color mb-0 bg-light">
                            <i className="bi bi-check-lg text-primary me-1"></i>Life time stadistics
                          </li>
                          <li className="list-group-item d-flex heading-color mb-0 bg-light">
                            <i className="bi bi-check-lg text-primary me-1"></i> Unlimited life-time analysis
                          </li>
                            <li className="list-group-item d-flex heading-color mb-0 bg-light">
                              <i className="bi bi-check-lg heading-color me-1"></i>Lookup insight of deep-analitics on tables
                            </li>
                         
                            <li className="list-group-item d-flex heading-color mb-0 bg-light">
                              <i className="bi bi-check-lg heading-color me-1"></i>API access to database
                            </li>

                            <li className="list-group-item d-flex heading-color mb-0 bg-light">
                              <i className="bi bi-check-lg heading-color me-1"></i>24/7 Support
                            </li>
                          </ul>

                          {/* Button */}
                          <Link to="/login" className="btn btn-primary mb-0 w-100">Buy suscription</Link>
                        </div>
                      </div>
                    </div>
                </div>

                {/* Item */}
             
              </div>

              {/* Slider Pagination */}
              <div className="swiper-pagination swiper-pagination-primary position-relative mt-4"></div>
            </div>
            {/* Slider END */}
          </div>
          {/* Pricing items END */}
        </section>

      <Preguntas/> 


      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="container">
      <h4>Statistics and real-time roulette tracking</h4>
        In this section, players are given a unique opportunity to follow live statistics of roulette games from leading developers such as Evolution, Ezugi, Pragmatic, and Playtech.

        Statistics include a variety of data, such as the frequency of numbers, colors, dozens, neighbors on the roulette wheel, sectors, and much more. The tracker interface ensures transparency and openness, allowing players to make more informed decisions based on the provided information.

        Such a tracker is a useful tool for analyzing trends and patterns in games, which can help players develop their strategies and increase their chances of success. All this creates an engaging and interactive experience for gambling enthusiasts, providing them with access to important real-time data.
        <br/>
      <br/>
      <br/>
      <br/>
       </div>
       </main>

      </>
      
      );

    }
  }


export default Landing;
