import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { Link, useHistory } from "react-router-dom";

import QuestionsImg from "../assets/questions.png"

import {
  auth,
  registerWithEmailAndPassword,
  registerInWithGoogle,
} from "../firebase";

function Register() {


  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);


  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const [ locationKeys, setLocationKeys ] = useState([])
  
  const register = () => {
    registerWithEmailAndPassword(email.toLowerCase(), password,name,answers);
  };  

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
  
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
  
          setStep(++step)
  
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
  
          setStep(--step)
  
        }
      }
    })
  }, [ locationKeys, ])


  function LoadUser() {

    if (loading) {
      return;
    }
    if (user) { history.replace("/tables")  } 
  }

  useEffect(() => {
    LoadUser()
  }, [user, loading]);


  function RegisterForm()    {
      return (
            <div className=" ">
                <div className="container g-0">
                <h2 className="mb-0">Create your account
                </h2>
                <p className="mb-0">Start your StakeBits account</p>

                {/* Social buttons */}
                          
                {/* Form START */}
                <form>
                  {/* Email */}
                  <div className="input-floating-label form-floating mb-4">
                    <input type="email"
                           className="form-control mt-5"
                           id="floatingInput"
                           placeholder="name@example.com"
                           value={email}
                          onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>

                  {/* Password */}
                  <div className="input-floating-label form-floating position-relative">
                    <input 
                        className="form-control fakepassword pe-6" id="psw-input"
                        placeholder="Enter your password"
                        value={password}
                         type={
                            showPassword ? "text" : "password"
                         }
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    <label htmlFor="floatingInput">Password</label>
                    <span className="position-absolute top-50 end-0 translate-middle-y p-0 me-2">
                      <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" 
                      onClick={() =>
                          setShowPassword(!showPassword)
                      }
                      ></i>
                    </span>
                  </div>
                  {/* Pswmeter */}

                  <div className="mb-4 mt-3">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="checkbox-1"/>
                      <label className="form-check-label" htmlFor="checkbox-1" ><Link> Acepts terms and conditions</Link> y <a href="https://stakebits.co/terms" target="__blank">políticas de privacidad </a></label>
                    </div>
                  </div>
                  {/* Button */}
                  <div className="align-items-center mt-0">
                    <div className="d-grid">
                      <button className="btn btn-primary mb-0" type="button" onClick={() => register()}>Create account</button>
                    </div>
                  </div>
                </form>

                  <div id="pswmeter" className="mt-3"></div>
                  
            
                  <div className="position-relative my-5">
                    <hr/>
                    <p className="small position-absolute top-50 start-50 text-primary translate-middle bg-body px-4">Or</p>
                  </div>

                      <div className="row mt-2 mb-3">
                  {/* Social btn */}
                  <div className="col-xl-12 d-grid">
                    <a onClick={() => registerInWithGoogle(answers,name)} className="btn border-primary text-primary bg-white mb-2 mb-xxl-0"><i className="fab fa-fw fa-google text-google-icon me-2"></i>Login with google</a>
                  </div>
                  
                  </div>
                  </div>
                </div>
        )
  }





  return(
      <div className="">
        <div className="row">
          {/* left */}
          <div className="col-lg-6 mt-5 d-flex m-auto ">
            <div className="row ">             
             {  RegisterForm()}

                <div className="mt-4 text-center">
                  <span>Already have an account? <Link to="/login"> Log in </Link></span>
                </div>
                
            </div>
          </div>

         
              </div>

    </div>

  )
}


export default Register;
